export const environment = {
  env: 'production',
  production: true,
  BACKEND_URL: 'https://api.galena.com',
  LEGACY_BACKEND_URL: 'https://api.edupass.io',
  FALCON_URL: 'https://falcon.galena.com',
  NEW_API_ENDPOINT: 'https://api2.edupass.io',
  FRONTEND_URL: 'https://plataforma.galena.com',
  registry_limit: 100,
  WHATSAPP_NUMERO: '5511987566079',
  MEDIA_URL: 'https://media.edupass.io',
  PURPLE_METRICS_COMPANY_ID: '137f281d-fda9-461c-bcec-5eeb1a2dd518',
};
